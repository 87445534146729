.App {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.city {
  top: 0;
  margin-top: 0;
  padding-top: 0;
  background-image: url("./images/pexels-3894035.jpg");
  background-attachment: fixed;
  background-size: cover;
}

.footer {
  margin-top: calc(50px + 3vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: #fff;
  height: calc(30px + 2vmin);
  width: 100%;
}

.font-thin {
  font-weight: 300;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.3);
}

.header .logo {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: calc(30px + 3vmin);
}

.header .logo h1 {
  font-size: xx-large;
  padding-bottom: 0.5vmin;
  margin-bottom: 0px;
}

.header .logo h1 a {
  text-decoration: none;
  color: #000;
  transition: 0.4s;
}

.header .logo h1 a:hover {
  color: #ff0048;
}

.header .logo h3 {
  font-size: large;
  font-style: italic;
  padding-top: 0px;
}

.header .navigation {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: calc(30px + 3vmin);
}

.header .navigation ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}

.header .navigation ul li {
  padding-left: calc(5px + 1vmin);
  padding-right: calc(5px + 1vmin);
}

.header .navigation ul li a {
  text-decoration: none;
  color: #000;
}

.header .navigation ul li a:hover {
  text-decoration: none;
  color: #ff0048;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 3vmin;
  margin-top: 12vmin;
  padding-top: calc(15px + 3vmin);
  border-radius: 15px;
  min-height: 60vmin;
  width: 85%;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.content a,
.dual-content a {
  color: #ff0048;
}

.content a:hover,
.dual-content a:hover {
  color: #fff;
}

.dual-content {
  width: 85%;
  display: flex;
  justify-content: space-between;
}

.content-left-small {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 3vmin;
  margin-top: 12vmin;
  padding-top: calc(15px + 1vmin);
  border-radius: 15px;
  min-height: 60vmin;
  width: 30%;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.content-right-large {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 3vmin;
  margin-top: 12vmin;
  padding-top: calc(15px + 1vmin);
  border-radius: 15px;
  min-height: 60vmin;
  width: 65%;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
}

.contact-info a {
  color: #ff0048;
}

.contact-info a:hover {
  color: #fff;
}

.contact-info .contact-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin-bottom: 1vmin;
}

.contact-info .contact-item * {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact-info .contact-label {
  min-width: 15vmin;
}

.contact-info .contact-item a {
  text-decoration: none;
}

.contact-map {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-map iframe {
  width: 90%;
}

.form-field {
  display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: left;
  margin: calc(10px + 1vmin);
  align-items: center;
}

.form-field .form-label {
  min-width: 35%;
}

.form-field .form-input {
  min-width: 50%;
}

.form-field .form-input input {
  border-radius: 10px;
  border: 0px;
  height: calc(25px + 1vmin);
  width: 100%;
  padding-left: 2vmin;
}

.form-field .form-input textarea {
  border-radius: 10px;
  border: 0px;
  height: calc(150px + 3vmin);
  width: 100%;
  padding-left: 2vmin;
  padding-top: 1vmin;
}

.form-field .form-input input[type="submit"] {
  border-radius: 10px;
  border: 0px;
  height: calc(25px + 2vmin);
  width: calc(100% + 2.5vmin);
  transition-duration: 0.4s;
  font-weight: bold;
}

.form-field .form-input input[type="submit"]:hover {
  background-color: #ff0048;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .dual-content {
    display: flex;
    flex-direction: column;
  }
  .dual-content div {
    width: 100%;
  }
  .content-right-large {
    padding-bottom: 3vmin;
  }
  .header .navigation {
    display: none;
  }
  .header {
    padding: 0;
    justify-content: center;
  }
  .header .logo {
    padding: 0;
    text-align: center;
  }
}
